import React, {useState, useEffect} from 'react';
import { FaDiscord, FaBars, FaTwitter, FaInstagram } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import bannericon from '../../images/banner-icon2.png';
import {Nav, NavbarContainer, NavLogo, NavLogImg, MobileIcon, NavMenu, NavItem, NavLinks, NavBtn, NavBtnLink, SocialIconLink, SocialIcons} from './NavbarElements';

const Navbar = ( {toggle} ) => {
    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80) {
            setScrollNav(true)
        } else {
            setScrollNav(false)
        }
    };

    useEffect (() => {
        window.addEventListener('scroll', changeNav)
    }, []);

    const toggleHome = () => {
        scroll.scrollToTop()
    };
    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo to='/' onClick={toggleHome}><NavLogImg src={bannericon}/></NavLogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars/>
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLinks to="about"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >ABOUT</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="society"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >SOCIETY
                            </NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="roadmap"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >ROADMAP</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="saleplan"
                            smooth={true} duration={500} spy={true} exact='true' offset={-80}
                            >SALE PLAN</NavLinks>
                        </NavItem>
                            <SocialIconLink href="//discord.gg/STkQSkd2fm/" target="_blank" aria-label="Discord">
                                <FaDiscord />
                            </SocialIconLink>
                            <SocialIconLink href="//www.instagram.com/wantedfoxes/" target="_blank" aria-label="Twittwer">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="//twitter.com/WantedFoxes/" target="_blank" aria-label="OpenSea">
                                <FaTwitter />
                            </SocialIconLink>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="/">MINT(Coming soon)</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
        </>
    )
}

export default Navbar