import React from 'react';
import bounty from '../../images/bounty.png';
import wallet from '../../images/wallet.png';
import dao from '../../images/dao.png';
import p2022 from '../../images/2022.png';
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements';

const Services = () => {
    return (
        <ServicesContainer id="roadmap">
            <ServicesH1>ROADMAP</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={bounty} />
                    <ServicesH2>Crazy Sale</ServicesH2>
                    <ServicesP>Plz refer to Sale plan. 48Hr After 100% sale, Top 1 holder will get 5% of the bounty.</ServicesP>
                    <ServicesP>The 2nd will get 3%, and 3th will get 2%.</ServicesP>
                    <ServicesP>And 0.1% will be randomly distributed to 10 holders.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={wallet} />
                    <ServicesH2>Society Wallet</ServicesH2>
                    <ServicesP>After rewarded the Top holders, 50% of the bounty from royalties will be transferred to society Wallet.</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={dao} />
                    <ServicesH2>DAO</ServicesH2>
                    <ServicesP>Eligo Society DAO establishment</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={p2022} />
                    <ServicesH2>Next Phase</ServicesH2>
                    <ServicesP>We plan to have 3 more NFTs for 2022</ServicesP>
                    <ServicesP>Let's join us to vote for the Craziest Plan.</ServicesP>
                </ServicesCard>
            </ServicesWrapper>
            
        </ServicesContainer>
    )
}

export default Services
