import React from 'react';
import { SidebarContainer,Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SideBtnWrap, SidebarRoute } from './SiderbarElements';

const Siderbar = ( {isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="about" onClick={toggle}>
                        ABOUT
                    </SidebarLink>

                    <SidebarLink to="society" onClick={toggle}> 
                        SOCIETY
                    </SidebarLink>

                    <SidebarLink to="roadmap" onClick={toggle}>
                        ROADMAP
                    </SidebarLink>
                    <SidebarLink to="saleplan" onClick={toggle}>
                        SALE PLAN
                    </SidebarLink>
                </SidebarMenu>
                <SideBtnWrap>
                    <SidebarRoute to='/'>Mint (Coming soon)</SidebarRoute>
                </SideBtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Siderbar
