import React, {useState} from 'react';
import Video from'../../videos/video.mp4';
import HeroBgImg123 from '../../images/banner2.png';
import { HeroContainer, HeroBg, VideoBg, HeroBgImg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import { Button } from '../ButtonElement';

const HeroSection = () => {
    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(!hover)
    }

    return (
        <HeroContainer id="home">
            <HeroBg>
                <HeroBgImg src={HeroBgImg123} />
            </HeroBg>
            <HeroContent>
                <HeroH1>Wanted Foxes</HeroH1>
                <HeroP>
                    ~ Welcome Bounty Hunters ~
                </HeroP>
                <HeroBtnWrapper>
                    <Button to="home" onMouseEnter={onHover} onMouseLeave={onHover} primary="true" dark="true">
                        Get Started {hover ? <ArrowForward/> : <ArrowRight/>}
                    </Button>
                </HeroBtnWrapper>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
