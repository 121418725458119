import React from 'react'
import { animateScroll as scroll} from 'react-scroll'
import footerlogo from '../../images/banner-icon2.png'
import { FaDiscord, FaInstagram, FaTwitter } from 'react-icons/fa'
import { 
    FooterContainer, 
    FooterWrap, 
    FooterLinksContainer, 
    FooterLinkWrapper, 
    FooterLinkItems, 
    FooterLinkTitle, 
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    FooterLogo,
    SocialIcons,
    SocialIconLink,
    WebsiteRights
} from './FootElements'

const Footer = () => {
    const toggleHome = () => {
        scroll.scrollToTop()
    };

    return (
        <FooterContainer>
            <FooterWrap>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            <FooterLogo src={footerlogo}/>
                        </SocialLogo>
                        <WebsiteRights>© {new Date().getFullYear()} Copyright Eligo Society</WebsiteRights>
                        <SocialIcons>
                            <SocialIconLink href="//discord.gg/STkQSkd2fm/" target="_blank" aria-label="Discord">
                                <FaDiscord />
                            </SocialIconLink>
                            <SocialIconLink href="//www.instagram.com/wantedfoxes/" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                            <SocialIconLink href="//twitter.com/WantedFoxes/" target="_blank" aria-label="Twittwer">
                                <FaTwitter />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
        </FooterContainer>
    )
}

export default Footer
