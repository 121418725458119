import styled from "styled-components";

export const SalesContainer = styled.div`
height: 500px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #fff;

@media screen and (max-width: 1300px) {
    height: 600px;
}

@media screen and (max-width: 768px) {
    height: 800px;
}
`;

export const SalesH1 = styled.h1`
font-size: 4rem;
color: #FF0000;
margin-bottom: 64px;


@media screen and (max-width: 480px) {
    font-size: 2rem;
}
`;

export const SalesTable = styled.table`
border: 1px solid white;
width: 90%;
`;

export const SalesTR = styled.tr`

`;

export const SalesTH = styled.th`
font-size: 2rem;
border: 1px solid black;
color: #6C0C6D;
`;

export const SalesTD = styled.td`
font-size: 2rem;
border: 1px solid black;
align-items: center;
`;

export const SalesTDF = styled.td`
font-size: 2rem;
border: 1px solid black;
color: #FF0000;
align-items: center;
`;