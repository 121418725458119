import React from 'react';
import {SalesContainer, SalesH1, SalesTable, SalesTR, SalesTD, SalesTDF, SalesTH} from './SalePlanElements';

const Sales = () => {
    return (
        <SalesContainer id="saleplan">
            <SalesH1>Sale Plan</SalesH1>
            <SalesTable>
                <SalesTR>
                    <SalesTH>Wanted Foxes No.</SalesTH>
                    <SalesTH>No. of WANTED FOXES</SalesTH>
                    <SalesTH>ETH Price</SalesTH>
                </SalesTR>
                <SalesTR>
                    <SalesTDF>1 - 300</SalesTDF>
                    <SalesTDF>300 (For whitelisted member only)</SalesTDF>
                    <SalesTDF>Only Gas Fee</SalesTDF>
                </SalesTR>
                <SalesTR>
                    <SalesTD>301 - 3,000</SalesTD>
                    <SalesTD>2,700</SalesTD>
                    <SalesTD>0.05ETH</SalesTD>
                </SalesTR>
                <SalesTR>
                    <SalesTDF>3,001 - 3,300</SalesTDF>
                    <SalesTDF>300</SalesTDF>
                    <SalesTDF>Only Gas Fee</SalesTDF>
                </SalesTR>
                <SalesTR>
                    <SalesTD>3,301 - 6,000</SalesTD>
                    <SalesTD>2,700</SalesTD>
                    <SalesTD>0.06ETH</SalesTD>
                </SalesTR>
                <SalesTR>
                    <SalesTDF>6,001 - 6,200</SalesTDF>
                    <SalesTDF>200</SalesTDF>
                    <SalesTDF>Only Gas Fee</SalesTDF>
                </SalesTR>
                <SalesTR>
                    <SalesTD>6,201 - 10,000</SalesTD>
                    <SalesTD>3,800</SalesTD>
                    <SalesTD>0.07ETH</SalesTD>
                </SalesTR>
            </SalesTable>
            
        </SalesContainer>
    )
}

export default Sales
