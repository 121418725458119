import styled from "styled-components";

export const ServicesContainer = styled.div`
height: 700px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background: #010606;

@media screen and (max-width: 1300px) {
    height: 1000px;
}

@media screen and (max-width: 768px) {
    height: 1800px;
}
`;

export  const ServicesWrapper = styled.div`
max-width: 1200px;
margin: 0 auto;
display: grid;
grid-template-columns: 1.2fr 1.2fr 1fr 1.1fr;
align-items: center;
grid-gap: 16px;
padding: 0 50px;

@media screen and  (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
}

@media screen and  (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
}
`;

export const ServicesCard = styled.div`
background: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 10px;
max-height: 460px;
padding: 30px;
box-shadow: 0 1px 3px rgba(0,0,0,0,2);
transition: all 0.2s ease-in-out;

&:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
}
`;

export const ServicesIcon = styled.img`
height: 100px;
width: 100px;
margin-bottom: 10px;
`;

export const ServicesH1 = styled.h1`
font-size: 4rem;
color: #FF0000;
margin-bottom: 64px;


@media screen and (max-width: 480px) {
    font-size: 2rem;
}
`;

export const ServicesH2 = styled.h2`
font-size: 2rem;
color: #6C0C6D;
margin-bottom: 10px;
`;

export const ServicesP = styled.p`
font-size: 1.5rem;
text-align: center;
`;