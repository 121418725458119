export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ABOUT',
    headline: 'WANTED FOXES',
    description: 'What have Wanted Foxes done? Betray? Corruption? Defraud? or...Knowing too much?',
    description2: 'Who is hunting the foxes?',
    description3: 'Wanted Foxes is a collection of 10,000 uniquely generated foxes with 165 traits who are hiding in the ETH blockchain.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: 'images/example.gif',
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
};

export const homeObjTwo = {
    id: 'society',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'SOCIETY',
    headline: 'ELIGO SOCIETY',
    description: 'We are going to build a kingdom. All members will be a citizen of this society who can involve our growth. The Latin word "Eligo" means "Choose", our inspirations are all about chosen. Choosing to be greedy, choosing to be wicked, choosing to be curious, are all represent Wanted Foxes. And we will choose to be anything in the coming future. ',
    buttonLabel: 'Learn more',
    imgStart: false,
    img: 'images/EligoSociety.png',
    alt: 'fds',
    dark: false,
    primary: false,
    darkText: true
};
